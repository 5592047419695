module.exports.customDomains = {
  'cheguevarauu.ru': 'che-guevara-owah',
  'rest.dimare-hall.ru': 'di-mare-hall-restaurant-pub-uazj',
  'restoprojects.ru': 'manufaktura-dkju',
  'anapa-restoran.ru': 'restorannyj-kompleks-gorki-jfhx',
  'menu.mama-ispekla.ru': 'pekarnya-mama-ispekla-xdec',
  'estetikavkusa38.ru': 'estetika-vkusa-mwsy',
  'menu.fdn.gg': 'pony',
  'cafera.ru': 'cafera',
  'rybtorg.moscow': 'rybtorgmoscow',
  'delivery.bonappcafe.ru': 'bonapp',
  'menu.bonappcafe.ru': 'bonapp',
  'delivery.nosorogcafe.ru': 'nosorog-volgograd',
  'delivery.crimeazavidovo.ru': 'crimeazavidovo',
  'delivery.maxima-pizza.ru': 'maxima-pizza-msk',
  'derevnya.yarkoe-country.ru': 'derevnya-yarkoe',
  'menu.sattva.moscow': 'sattva-msk',
  'menu.mojolounge.ru': 'mojo-lounge-vidnoe',
  'boncourage.ru': 'bon-courage-borisoglebsk',
  'menu.fijicafe.ru': 'balanceat-msk',
  'tmp1.fdn.gg': 'pony',
  'bubluiteka.ru': 'playbar-bubluiteka-vladikavkaz',
  'menu.pivnoyzaboy.ru': 'bar-zaboy-murmansk',
  'agatatmn.ru': 'shokoladnica-jkba',
  'goroddorog.club': 'gorod-dorog-rxer',
  'catalogo.cleanairperu.com': 'cleanair-lfrh',
  'xn--e1ane2e.xn--80aaa3bbcpd6axw1je.xn--p1ai': 'alanskaya-kuhnya-yyzq', // МЕНЮ.АЛАНСКАЯКУХНЯ.РФ
  'catalogo.smokecuscoshop.com': 'smoke-cusco-shop-ajiy',
  'millikazan.ru': 'milli-nxku',
  'xn--e1ane2e.xn--72-6kcqf0cchrk.xn--p1ai': 'semejnoe-kafe-anderson-blof', //меню.андерсон72.рф
  'delivery.caspian.moscow': 'restoran-kaspian-janu',
  'madliani.ru': 'madliani-yfeq',
  'arigato68.ru': 'arigato-zujn',
  'zabegalovka.group': 'zabegalovka-pcjq',
  'gwcoffee.ru': 'green-white-coffee-yotv',
  'coffeegrad.ru': 'coffee-grad-nzgd',
  'mangal-house1.ru': 'mangal-house-umiw',
  'roobar.ru': 'gril-bir-jdte',
  'seafood.su': 'seafood-rossijskie-moreprodukty-odjq',
  'xn----8sb4adwfaa9db8a.xn--p1ai': 'myaso-na-uglyah-sssr-kbol', //СССР-ШАШЛЫК.РФ
  'makaevskie-polufabrikati.ru': 'makaevskie-polufabrikaty-lwnp',
  'boutique.myrecept.ru': 'myrecept-boutique-pfbn',
  'solemio.menu': 'sole-mio-gwxm',
  'xn--b1aqkdfjn.com': 'provkus-hnuf', //провкус.com
  'xn--b1aglesln.xn--p1ai': 'rok-kafe-trojka-xulc', //втройке.рфб
  'kondi.myrecept.ru': 'myrecept-boutique-2',
  'cheshirecafe.com.au': 'cheshire-dcxh',
  'demo.fdn.gg': 'demo',
  '953-002-00-99.ru': 'it-pizzaru-lxqz',
  'sakura-place.ru': 'lanshan-msk',
  'midiivdomike.ru': 'midii-v-domike-vfty',
  'fitteria.ru': 'obedov-uzqw',
  'menu.4burros.ru': 'takeriya-cuatro-burros-wrwh',
  'foodda.kz': 'foodda-wenr',
  'nude.scan-2-get.com': 'nude-food-augsburg-ycsw',
  'dscafe.ru': 'dolce-salato-snbr',
  'corso.scan-2-get.com': 'corso-augsburg-cwln',
  'mangal-1.ru': 'mangal-reutov',
  'menu.librarybar.ru': 'the-library-bar-wypv',
  'orioncafe.ru': 'cafe-orion-iqgf',
  'fan.pizza': 'fanpizza-xyjb',
  'kurkuli.vn.ua': 'kurkuli-jbtj',
  'bakaricafe.ru': 'bakari-text',
  'dolce2.ru': 'dolce-salato-tolstogo',
  'lavashrest.ru': 'lavash-dkaa',
  'orionvypechka.ru': 'konditerskaya-orion-bnsx',
  'xn--2-itbuf9f.xn--80aaa3bbcpd6axw1je.xn--p1ai': 'alanskaya-kuhnya-2',
  'xn--3-itbuf9f.xn--80aaa3bbcpd6axw1je.xn--p1ai': 'alanskaya-kuhnya-3',
  'xn--4-itbuf9f.xn--80aaa3bbcpd6axw1je.xn--p1ai': 'alanskaya-kuhnya-4',
  'xn--5-itbuf9f.xn--80aaa3bbcpd6axw1je.xn--p1ai': 'alanskaya-kuhnya-5',
  'xn--6-itbuf9f.xn--80aaa3bbcpd6axw1je.xn--p1ai': 'alanskaya-kuhnya-6',
  'govindas.pro': 'kafe-konditerskaya-govindas-cvat',
  'shawarmagods.ru': 'shawarmagods-zomk',
  'pirs23.ru': 'pirs-23-meww',
  'duduki.ru': 'kafe-gruzinskoj-kuhni-duduki-wjzr',
  'lavalips.in': 'lava-lips-iufh',
  'venue.foodeon-proxy.com': 'demo',
  'boutique-has.myrecept.ru': 'myrecept-boutique-ekbr',
  'limonchello-bratsk.ru': 'limonchelo-zfxk',
  'menu-orehovo.chaihana-halva.ru': 'chajhana-halva-cidt',
  'menu-lublino.chaihana-halva.ru': 'chajhana-halva-dmcs',
  'churchilluu.com': 'cherchill-ojen',
  'celentanouu.com': 'karaoke-rooms-chelentano-zfux',
  'zclubuu.com': 'zclub-uakv',
  'goodgoalbar.ru': 'bar-horoshij-gol-yxmr',
  'tverskaya.sattva.moscow': 'sattva-kiez',
  'lunchtimeeda24delivery.ru': 'lunch-time-tznv',
  'aloha46.ru': 'piratskaya-pristan-mznz',
  'sushi-ot-alice.ru': 'sushi-ot-alisy-qbmk',
  'umamiptz.ru': 'umami-pgbj',
  'xn--80anc1a.xn--p1ai': 'piza-txmp', //пиза.рф
  'xn--80acqd8adn.xn--p1ai': 'piza-txmp', //пизаспб.рф
  'morospb.ru': 'moro-gastrobar-lzsk',
  'syemslona-bratsk.ru': 'suem-slona-urax',
  'drmoro.ru': 'moro-gastrobar-iqym',
  'chikarno-delivery.ru': 'chikarno-restoran-horoshih-lyudej-bfpg',
  'xn--e1ane2e.xn--90aiiaj5aee.xn--p1ai': 'beerkeeper-zwav', //меню.биркипер.рф
  'chitogvrito.info': 'chito-gvrito-tztl',
  'menu-milkovo.chaihana-halva.ru': 'chajhana-halva-dmcs',
  'grilledchicken.ru': 'grilled-shicken-wdmy',
  'xn--e1ane2e.xn--80aaco5bemccfl7i.xn--p1ai': 'restoran-ryba-zwml', //меню.ресторанрыба.рф
  'eda-torbeevo.ru': 'kamelot-or-picca-i-rolly-pwam',
  'shaurmavaax.ru': 'shaurma-vaah-mangal-pgjj',
  'xn--80aaa3abcjjp0al9aqg5a.com': 'madliani-tchj', //хинкалихачапури.com
  'hellopoke.ru': 'hello-poke-alfq',
  'lazzat.one': 'chajhana-lazzat-ciyu',
  'morepoke-saransk.ru': 'more-poke-xptz',
  'pprn.ru': 'peperon-ebkk',
  'menu.casalimone.ru': 'casa-limone-fxyq',
  'pub512.ru': 'pub512-khgh',
  'xn--l1ab8c.xn--80adxhks': 'panaziya-rzze', //ням.москва
  'shishkalounge.ru': 'shishka-launzh-csnt',
  'sushishop09.ru': 'sushi-shop-jryt',
  'xn--80ajbthbmqdw.xn--p1ai': 'black-rabbit-mfma', //моремаркет.рф
  'xn--38-6kca3cvbes.xn--p1ai': 'sakura-jjcj', //сакура38.рф
  'rayyan.ru': 'chajhona-rajjan-vfzo',
  'bg-lounge.ru': 'bg-lounge-txpq',
  'bikshashlik.ru': 'byk-shashlyk-hsem',
  'xn--138-5cda3d0bfu.xn--p1ai': 'sushi-bar-sakura-iewm', //сакура138.рф
  'svoi-bar.svoi-unity.ru': 'svoi-ljco',
  'lunch500.online': 'supandsalad-xcnt',
  'msb.rest': 'restoran-moj-sosnovyj-bor-dxoj',
  'pizza-hub.pro': 'pizza-and-coffee-hub-nswq',
  'xn--80agfabniig8cey9dff.xn--p1ai': 'sushi-like-wcfq', //сушилайкшерегеш.рф
  'katerinsicatch.ru': 'katerinsicatch-owsr',
  'xn--80ahuanckg.xn--p1ai': 'rollodar-hhgq', //роллодар.рф
  'msbsushi.ru': 'msb-sushi-kasg',
  'sushibar1.ru': 'sushi-bar-1-rvcn',
  'xn--80aaa3abcjjp0al9aqg5a.xn--p1ai': 'hinkali-hachapuri-jcwm', //хинкалихачапури.рф
  'berezka-menu.kz': 'berezka-wthz',
  'irkpizza.ru': 'picca-10-ivub',
  'gangs-restaurant.ru': 'saxra-twnu',
  'salettacoffee.com': 'salettacoffee-qbwh',
  'giradiko-delivery.ru': 'giradiko-wmav',
  'alazanspb.ru': 'hinkali-v-alazani-eufj',
  'ardenne.originalbelgian.ru': 'belgijskij-pab-bryussel-ikhh',
  'brussels.originalbelgian.ru': 'belgijskij-pab-bryussel-adrq',
  'gent.originalbelgian.ru': 'belgijskij-pab-bryussel-chfz',
  'waterloo.originalbelgian.ru': 'belgijskij-pab-bryussel-hurf',
  'waterzooi.originalbelgian.ru': 'pab-vaterzoj-wdeh',
  'brugge.originalbelgian.ru': 'belgijskij-pab-brugge-guih',
  'borodashvili.com': 'borodashvili-edsz',
  'giradiko-delivery.pyatigorsk.ru': 'giradiko-wmav',
  'cult-gastrobar.ru': 'kult-keqg',
  'dolcetolstogo.ru': 'dolce-salato-atxu',
  'pizzamem.ru': 'pizzamem-zyaf',
  'salom24.ru': 'salom-mlsn',
  'pizza1-0.ru': 'picceriya-10-ihom',
  'pizza1-0kino.ru': 'picceriya-10-zyqi',
  'wokkertmn.ru': 'vokker-cgrn',
  'shokoladnicatmn.ru': 'shokoladnica-wfql',
  'moya-semia-vyselki.ru': 'moya-semya-hvns',
  'tsehdolce.ru': 'ceh-dolche-salato-dego',
  'seafoodsushi.ru': 'seafood-nmxz',
  'bahor-24.ru': 'bahor-dwmd',
  'hanlybazar.ru': 'kafe-gwcx',
}
